/* eslint-disable */
import { Box, Flex } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { ChatRoute } from 'models/ChatRoute';

export default function AdminNavbar(props: {
	secondary: boolean;
	routes: ChatRoute[];
	onOpen: (...args: any[]) => any;
	onClick: React.MouseEventHandler<HTMLButtonElement>
	onDeleteThread: (threadId: string) => void;
}) {
	const [ scrolled, setScrolled ] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	return (
		<Box
			position='fixed'
			left='30px'
			px='15px'
			pt='8px'
			top='18px'
		>
			<Flex
				alignItems={{ xl: 'center' }}
				display={{ xl: 'none'}}
			>
				<Box ms="auto" w={['50px']}>
          			<AdminNavbarLinks secondary={props.secondary} routes={props.routes} onClick={props.onClick} onDeleteThread={props.onDeleteThread} />
        		</Box>
			</Flex> 
		</Box>
	);
}
