import { Timestamp } from "firebase/firestore";
import { Thread } from "models/Thread";
import { Dictionary } from "utils/aliases"

export class ThreadParser {
    public parse(threadsData: Dictionary): [Thread] | [] {
        if (Object.keys(threadsData).length === 0) {
            return []
        }

        var threads = []
        for (const threadId in threadsData) {
            const data = threadsData[threadId]
            const thread: Thread = {
                id: threadId,
                name: data.prompt,
                dateCreated: this.convertToDate(data.dateCreated)
            }

            threads.push(thread)
        }

        return threads as [Thread]
    }
    
    private convertToDate(data: Dictionary): Date {
        const timestamp = new Timestamp(data._seconds, data.nanoseconds)
        return timestamp.toDate()
    }
}