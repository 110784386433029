/*eslint-disable*/
import MessageBox from 'components/chat/MessageChatBox';
import {
	Box,
	Button,
	Flex,
	Icon,
	Input,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { OpenAIModel } from 'types/types';
import { useParams } from 'react-router-dom';
import { UserService } from 'services/UserService';
import { OpenAIService } from 'services/OpenAIService';
import { Constants } from 'utils/constants';
import { FaRobot } from 'react-icons/fa';
import { FeedbackService } from 'services/FeedbackService';
import ChatEmptyView from 'views/EmptyStateView';

interface ChatParams {
	threadId: string
}

interface ChatContent {
	sender: string;
	message: string;
	messageId: string;
	metadata: any;
}

export default function Chat() {
	const { threadId } = useParams<ChatParams>()
	const userService = new UserService();
	const openAIService = new OpenAIService();
	const feedbackService = new FeedbackService();

	/**
	 * Colors
	 */
	const borderColor = useColorModeValue('gray.100', 'whiteAlpha.200');
	const inputColor = useColorModeValue('navy.700', 'white');
	const brandColor = useColorModeValue('brand.500', 'white');
	const gray = useColorModeValue('gray.500', 'white');
	const placeholderColor = useColorModeValue(
		{ color: 'gray.500' },
		{ color: 'whiteAlpha.600' },
	);

	const [inputOnSubmit, setInputOnSubmit] = useState<string>('');
	const [inputCode, setInputCode] = useState<string>('');
	const [outputCode, setOutputCode] = useState<string>('');
	const [model, setModel] = useState<OpenAIModel>('gpt-3.5-turbo');
	const [loading, setLoading] = useState<boolean>(false);
	const [chatHistory, setChatHistory] = useState<Array<ChatContent>>([]);
	const [initialResponse, setInitialResponse] = useState<string>('');
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const messagesEndRef = useRef(null);

	useEffect(() => {
		  messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	  }, [chatHistory, inputCode, outputCode]);

	const nameTruncationLength = 25

	useEffect(() => {
		getThreadHistory(threadId);
	}, [threadId])

	const getThreadHistory = (threadId: string) => {
		openAIService.getThreadHistory(threadId).then(data => {
			const sourcePattern = /【\d+:\d+†source】/g; 
        	const messages: ChatContent[] = data.map(message => {
            	const cleanedMessage = message.text.replace(sourcePattern, '');
            	return {
                	sender: message.role,
                	message: cleanedMessage,
					messageId: message.messageId,
					metadata: message.metadata
            	};
        	});

			setChatHistory(messages)
		}).catch(error => {
			/**
			 * Handle Error
			 */
			console.error(error)
		})
	};

	const sendFeedback = (prompt : string, result : string, messageId : string, index : number ) => {
		const documentId = localStorage.getItem(Constants.UserDocumentId)
		chatHistory[index].metadata.feedbackReceived = "true";
		feedbackService.post(documentId, prompt, result, threadId, messageId).then(() => {
			/**
			 * Do nothing
			 */
		}).catch(_ => {
			/**
			 * Handle Error
			 */
			delete chatHistory[index].metadata['feedbackReceived'];
		})
	}

	const onThumbsDownClicked = (index : number, output : string ) =>{ 
		const lastUserMessage = chatHistory
	     .slice(0, index) // Consider only the messages before the current assistant message
 	     .reverse() // Reverse to find the last user message first
		 .find(prevEntry => prevEntry.sender === 'user');  

		 if(chatHistory[index]?.metadata?.feedbackReceived){
			return;
		 }

		const lastUserMessageContent = lastUserMessage?.message;
		sendFeedback(lastUserMessageContent, output, chatHistory[index].messageId, index);
	}

	const handleTranslate = async () => {
		updateThreadNameIfNeeded(inputCode)
		setInputOnSubmit(inputCode);
		const userChatMessage: ChatContent = { sender: 'user', message: inputCode, messageId: '', metadata: {} };
		setChatHistory(currentHistory => [...currentHistory, userChatMessage]);
		// Chat post conditions(maximum number of characters, valid message etc.)
		const maxCodeLength = model === 'gpt-3.5-turbo' ? 700 : 700;

		if (!inputCode) {
			alert('Please enter your subject.');
			return;
		}

		if (inputCode.length > maxCodeLength) {
			alert(
				`Please enter code less than ${maxCodeLength} characters. You are currently at ${inputCode.length} characters.`,
			);
			return;
		}
		setOutputCode(' ');
		setInputCode('');
		setLoading(true);

		// -------------- Fetch --------------
		const response = await fetch(`${process.env.REACT_APP_ENV_API_ENDPOINT}/openai/assistant?threadId=${encodeURIComponent(threadId)}`, {
			method: 'POST',
			body: JSON.stringify({
				inputCode: inputCode,
			}),
			headers: {
				'content-type': 'application/json;charset=UTF-8',
			}
		})

		let messageId = '';

		async function streamToString(body: any) {
			const reader = body?.pipeThrough(new TextDecoderStream()).getReader();
			let chatbotResponse = "";
			while (reader) {
				let stream = await reader.read();
				if (stream.done) break;

				// Process each received chunk
				const chunks = stream.value
					.split("\n");
					
				//Add a space after each period that's not followed by a space
				const processedChunks = chunks.map((chunk: string) =>{
					if(chunk.startsWith('msg_')){
						messageId = chunk.substring(4);
						return;
					}
					return chunk.replace(/【\d+:\d+†source】/g, '') 
                	.replace(/\.([^ ])/g, '. $1')
                	.replace(/\.### /g, '. ###')
				}
				);
				chatbotResponse += processedChunks.join("\n");
				setOutputCode(chatbotResponse);
			}
			return chatbotResponse;
		}

		const chatbotResponse = await streamToString(response.body);
		const assistantChatMessage : ChatContent = { sender: 'assistant', message: chatbotResponse, messageId: messageId, metadata: {} };
		setChatHistory(currentHistory => [...currentHistory, assistantChatMessage]);
		setLoading(false);
		setOutputCode('');
	};

	const updateThreadNameIfNeeded = (prompt: string) => {
		const shortenPrompt = (prompt: string) => {
			if (prompt.length < nameTruncationLength) {
				return prompt
			}
	
			return prompt.substring(0, nameTruncationLength).concat('...')
		}

		if (chatHistory.length > 0) {
			return
		}
		
		const documentId = localStorage.getItem(Constants.UserDocumentId)
		const name = shortenPrompt(prompt)

		userService
			.updateThreadName(documentId, name, threadId)
			.then(() => {
				/**
				 * Do nothing
				 */
			}).catch(_ => {
				/**
				 * Handle Error
				 */
			})
	}

	const handleChange = (Event: any) => {
		setInputCode(Event.target.value);
	};

	const handleEnterPress = (Event: any) => {
		if (Event.key === 'Enter' && loading === false && inputCode) {
			handleTranslate();
		}
	};

	return (
		<Flex
			w="100%"
			pt={{ base: '70px', md: '0px' }}
			direction="column"
			position="relative"
		>
			{/* <Img
				src='https://cyborgmobile.com/assets/images/workforce-development/tnt-logo.png'
				position={'absolute'}
				w="350px"
				left="50%"
				top="50%"
				transform={'translate(-50%, -50%)'}
				opacity="3%"
			/> */}
			{/**
			 * Empty View when there are no messages
			 */}
			{ chatHistory.length === 0 &&
				<Box 
					position={'absolute'} 
					width='50%' 
					left="50%"
					top={['25%', '40%']}
					transform={'translate(-50%, -50%)'}
					>
					<ChatEmptyView />
				</Box>
			}
			<Flex
				direction="column"
				mx="auto"
				w={{ base: '100%', md: '100%', xl: '100%' }}
				minH={{ base: '75vh', '2xl': '85vh' }}
				maxW="1000px"
			>
				{/* Model Change */}
				<Flex direction={'column'} w="100%" mb="auto" />
				{/* Main Box */}

				<Flex direction="column" w="100%" mx="auto">
					{/**
					 * List of chat messages
					 */}
					{chatHistory.map((entry, index) => (
						<Flex 
						key={index + (initialResponse ? 2 : 0)} 
						w="100%" 
						align={'center'} 
						mb="15px" 
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(null)}
						>
							<Flex
								borderRadius="full"
								justify="center"
								align="center"
								bg={entry.sender === 'user' ? 'transparent' : '#005e80'}
								me="20px"
								h="40px"
								minH="40px"
								minW="40px"
							>
								{ entry.sender !== 'user' && 
									<Icon
										as={FaRobot}
										// as={entry.sender === 'user' ? MdPerson : FaRobot}
										width="20px"
										height="20px"
										color={entry.sender === 'user' ? brandColor : 'white'}
									/>
								}
							</Flex>
							<MessageBox output={entry.message} 
								sender={entry.sender} 
								showCommandBar = {hoveredIndex === index || index === chatHistory.length - 1} 
								onThumbsDownClicked={()=>onThumbsDownClicked(index, entry.message)} 
								feedbackGiven={entry.metadata?.feedbackReceived === "true" ? true : false}
							/>
						</Flex>
					))}
					
					{/**
					 * Chat Preview after hitting Submit
					 */}
					<Flex
						direction="column"
						w="100%"
						mx="auto"
						display={'flex'}
						mb="auto"
					>
						<Flex w="100%" 
						ref={messagesEndRef}
						>
							{outputCode && (
								<Flex
									borderRadius="full"
									justify="center"
									align="center"
									bg='#005e80'
									me="20px"
									h="40px"
									minH="40px"
									minW="40px"
								>
									<Icon
										as={FaRobot}
										width="20px"
										height="20px"
										color='white'
									/>
								</Flex>
							)}
							<MessageBox output={outputCode} 
								sender={'assistant'} 
								showCommandBar = {false} 
								onThumbsDownClicked={()=>onThumbsDownClicked(0, outputCode)}
								feedbackGiven={false}
							/>
						</Flex>
					</Flex>

					{/**
					 * Chat Input and Submit Button
					 */}
					<Flex
						ms={{ base: '0px', xl: '60px' }}
						mt="20px"
						justifySelf={'flex-start'}
						flexWrap={[ "wrap", "nowrap" ]}
						rowGap={5}
						columnGap={3}
					>
						<Input
							value={inputCode}
							minH="54px"
							h="100%"
							border="1px solid"
							borderColor={borderColor}
							borderRadius="10px"
							p="15px 20px"
							fontWeight="500"
							_focus={{ borderColor: 'none' }}
							color={inputColor}
							_placeholder={placeholderColor}
							placeholder="Type your message here..."
							onChange={handleChange}
							onKeyDown={handleEnterPress}
							bgColor="#fff"
						/>
						<Button
							bgColor={"brand.700"}
							color={"#fff"}
							py="20px"
							px="16px"
							fontSize="sm"
							borderRadius="10px"
							m="auto"
							flexGrow={1}
							w={{ base: '160px', md: '210px' }}
							h="54px"
							_hover={{
								bg: '#00384d',
								_disabled: {
									bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
								},
							}}
							onClick={inputCode ? handleTranslate : () => {}}
							isLoading={loading ? true : false}
						>
							Submit
						</Button>
					</Flex>

					{/**
					 * Disclaimer
					 */}
					<Flex
						justify="center"
						mt="20px"
						direction={{ base: 'column', md: 'row' }}
						alignItems="center"
					>
						<Text fontSize="sm" textAlign="center" color={gray}>
							The assistant may produce inaccurate information about people, places, or facts.
						</Text>
					</Flex>
				</Flex>
			</Flex>
			<div ref={messagesEndRef} />
		</Flex>
	);
}
