import { IconButton, useColorModeValue } from '@chakra-ui/react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Card from 'components/card/Card'
import {FaRegThumbsDown, FaThumbsDown} from 'react-icons/fa';
import { useEffect, useState } from 'react';

type SenderType = string

export default function MessageBox(props: { output: string, sender: SenderType, showCommandBar: boolean, onThumbsDownClicked : () => void, feedbackGiven: boolean}) {
  const { output, sender, showCommandBar, onThumbsDownClicked, feedbackGiven : initialFeedbackGiven } = props
  const textColor = useColorModeValue('navy.700', 'white')
  const [feedbackGiven, setFeedbackGiven] = useState(initialFeedbackGiven);

  useEffect(() => {
    setFeedbackGiven(initialFeedbackGiven);
  }, [initialFeedbackGiven]);

  const handleThumbsDownClick = () => {
    if (!feedbackGiven) {
      onThumbsDownClicked();
      setFeedbackGiven(true);
    }
  };
  return (
    <Card
      display={output ? 'flex' : 'none'}
      px="22px !important"
      pl="22px !important"
      color={textColor}
      fontSize={{ base: 'sm', md: 'md' }}
      lineHeight={{ base: '24px', md: '26px' }}
      fontWeight="500"
      height = "auto"
      borderRadius={10}
      bg={sender !== "user" ? "rgba(255, 255, 255, 0)" : "rgba(255, 255, 255, 0.8)"} 
    >
      <Markdown className="font-medium" remarkPlugins={[remarkGfm]}>
        {output ? output : ''}
      </Markdown>
      {showCommandBar && sender === 'assistant' && (
        <div style={{ position: 'relative', width: '100%' }}>
          <IconButton
            aria-label={feedbackGiven ? "thumbs-down" :"reg-thumbs-down"}
            icon={feedbackGiven ?  <FaThumbsDown /> : <FaRegThumbsDown />}
            variant="ghost"
            colorScheme="blue"
            size="sm"
            position="absolute"
            alignSelf="flex-start"
            onClick={handleThumbsDownClick}
          />
        </div>
      )}
    </Card>
  )
}
