import {
	Flex,
	useColorModeValue
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ChatRoute } from 'models/ChatRoute';
import { SidebarResponsive } from 'components/sidebar/Sidebar';

export default function HeaderLinks(props: { 
	secondary: boolean, 
	routes: ChatRoute[], 
	onClick: React.MouseEventHandler<HTMLButtonElement>,
	onDeleteThread: (threadId: string) => void }) {
	const { secondary, onDeleteThread } = props;
	let menuBg = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	return (
		<Flex
			w={{ sm: '100%', md: '100%' }}
			alignItems='center'
			flexDirection='row'
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p='10px'
			borderRadius='30px'
			boxShadow={shadow}
		>
			<SidebarResponsive routes={props.routes} onClick={props.onClick} onDeleteThread={onDeleteThread} />
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
