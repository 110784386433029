import axios, { AxiosRequestConfig } from "axios"
import { Thread } from "models/Thread"
import { ThreadParser } from "parsers/ThreadParser"
import { Constants } from "utils/constants"
import { Dictionary, DocumentId } from "utils/aliases"
import { HTTPMethods } from "services/HTTPMethods"

const API_GET_DOCUMENTS = `${process.env.REACT_APP_ENV_API_ENDPOINT}/admin/documents`
const API_UPDATE_DOCUMENTS = `${process.env.REACT_APP_ENV_API_ENDPOINT}/admin/document-write`
const API_UPDATE_USER_ACTIVE = `${process.env.REACT_APP_ENV_API_ENDPOINT}/admin/update-user-active`

export class FirebaseService {
    private threadParser = new ThreadParser()
    //Function to get threadId for a user 
    getThreadID(userDocumentId : DocumentId) : Promise<DocumentId>{
        return new Promise((resolve, reject) => {
                    const requestConfig: AxiosRequestConfig = {
                        method: HTTPMethods.get,
                        headers: {
                            [Constants.ContentType]: [Constants.ApplicationJson]
                        },
                        data: {
                            [Constants.DocumentId]: userDocumentId
                        }
                    }
                    
                    axios.get(API_GET_DOCUMENTS, requestConfig).then(response => {
                        const data = response.data.threads as Dictionary
                        const threads = this.threadParser.parse(data)
                        const threadId = threads[0].id
                        resolve(threadId)
                    }).catch(error => {
                        reject(error)
                    })
                })   
    }

    addThreadIDToUser(userDocumentId: DocumentId, thread: Thread) : Promise<void>{
        return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.post,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                },
                data: {
                    documentId: userDocumentId,
                    data: {
                        [thread.id] : {
                            prompt: thread.name,
                            dateCreated: thread.dateCreated
                        },
                    }
                }
            }
            
            axios.post(API_UPDATE_DOCUMENTS, requestConfig).then(response => {
                resolve();
            }).catch(error => {
                console.error('Failed to add thread ID:', error);
                reject(error);
            });
        })   
    }

    updateUserActive(userDocumentId: DocumentId): Promise<void> {
        return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.post,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                },
                data: {
                    documentId: userDocumentId
                }
            }

            axios.post(API_UPDATE_USER_ACTIVE, requestConfig).then(response => {
                resolve();
            }).catch(error => {
                console.error("Failed to update user active status:", error);
                reject(error);
            });
        });
    }
}
