import React from 'react';
import {
	Box,
	Flex,
	Drawer,
	DrawerBody,
	Icon,
	useColorModeValue,
	DrawerOverlay,
	useDisclosure,
	DrawerContent,
	DrawerCloseButton
} from '@chakra-ui/react';
import Content from 'components/sidebar/components/Content';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { IoMenuOutline } from 'react-icons/io5';
import { ChatRoute } from 'models/ChatRoute';

function Sidebar(props: { routes: ChatRoute[]; [x: string]: any, onClick: React.MouseEventHandler<HTMLButtonElement> ; onDeleteThread : (threadId : string) => void }) {
	const { routes, onClick, onDeleteThread } = props;

	let variantChange = '0.2s linear';
	let shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
	let sidebarBg = useColorModeValue('white', 'navy.800');
	let sidebarMargins = '0px';

	return (
		<Box display={['none', 'none', 'none', 'none', 'block']} position='fixed' minH='100%'>
			<Box
				bg={sidebarBg}
				transition={variantChange}
				w='300px'
				h='100vh'
				m={sidebarMargins}
				minH='100%'
				overflowX='hidden'
				boxShadow={shadow}>
				<Scrollbars
					autoHide
					>
					<Content routes={routes} onClick={onClick} onDeleteThread={onDeleteThread}/>
				</Scrollbars>
			</Box>
		</Box>
	);
}

// FUNCTIONS
export function SidebarResponsive(props: { routes: ChatRoute[], onClick: React.MouseEventHandler<HTMLButtonElement>; onDeleteThread : (threadId : string) => void  }) {
	let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
	let menuColor = useColorModeValue('gray.400', 'white');

	const onNewThreadClicked = (e: any) => {
		onClose()
		onClick(e)
	}
	// // SIDEBAR
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const { routes, onClick, onDeleteThread } = props;
	// let isWindows = navigator.platform.startsWith("Win");
	//  BRAND

	return (
		<Flex display={['flex', 'flex', 'flex', 'flex', 'none']} alignItems='center'>
			<Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
				<Icon
					as={IoMenuOutline}
					color={menuColor}
					my='auto'
					w='30px'
					h='30px'
					_hover={{ cursor: 'pointer' }}
				/>
			</Flex>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
				finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent w='285px' maxW='285px' bg={sidebarBackgroundColor}>
					<DrawerCloseButton
						zIndex='3'
						onClick={onClose}
						_focus={{ boxShadow: 'none' }}
						_hover={{ boxShadow: 'none' }}
					/>
					<DrawerBody maxW='285px' px='0rem' pb='0'>
						<Scrollbars autoHide>
							<Content routes={routes} onClick={(e) => {onNewThreadClicked(e)}} onThreadClicked={onClose} onDeleteThread={onDeleteThread}/>
						</Scrollbars>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Flex>
	);
}

export default Sidebar;
