import axios, { AxiosRequestConfig } from "axios"
import { Thread } from "models/Thread"
import { Dictionary } from "utils/aliases"
import { Constants } from "utils/constants"
import { HTTPMethods } from "./HTTPMethods"

const API_CREATE_THREAD = `${process.env.REACT_APP_ENV_API_ENDPOINT}/openai/create-thread`
const API_GET_THREAD_HISTORY = (threadId: string) => {
    return `${process.env.REACT_APP_ENV_API_ENDPOINT}/openai/retrieve-messages?threadId=${encodeURIComponent(threadId)}`
} 

export class OpenAIService {
    public createThread(): Promise<Thread> {
		return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.get,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                }
            }
            
            axios
                .post(API_CREATE_THREAD, requestConfig)
                .then(response => {
                    const thread: Thread = {
                        id: response.data.threadId ,
                        name: 'New Chat',
                        dateCreated: new Date(),
                    }
                    resolve(thread)                    
                }).catch(error => {
                    reject(error)
                })
        })
    }

    public getThreadHistory(threadId: string): Promise<Dictionary[]> {
		return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.post,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                }
            }
            
            axios
                .post(API_GET_THREAD_HISTORY(threadId), requestConfig)
                .then(response => {
                    resolve(response.data)                    
                }).catch(error => {
                    reject(error)
                })
        })
    }
}