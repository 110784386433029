import axios, { AxiosRequestConfig } from "axios"
import { Constants } from "utils/constants"
import { DocumentId } from "utils/aliases"
import { HTTPMethods } from "./HTTPMethods"

const API_POST_FEEDBACK = `${process.env.REACT_APP_ENV_API_ENDPOINT}/admin/feedback`
const API_POST_FEEDBACK_OPENAI = `${process.env.REACT_APP_ENV_API_ENDPOINT}/openai/feedback`

export class FeedbackService {
    public post(documentId: DocumentId, prompt: string, result: string, threadId: string, messageId: string): Promise<void> {
		return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.post,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                },
                data: {
                    [Constants.DocumentId]: documentId,
                    [Constants.Prompt]: prompt,
                    [Constants.Result]: result
                }
            }

            const requestConfigOpenAI: AxiosRequestConfig = {
                method: HTTPMethods.post,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson],
                },
                data: {
                    [Constants.ThreadId]: threadId,
                    [Constants.MessageId]: messageId,
                },
            };

            const feedbackPromise = axios.post(API_POST_FEEDBACK, requestConfig);
            const openaiFeedbackPromise = axios.post(API_POST_FEEDBACK_OPENAI, requestConfigOpenAI);
            
            return Promise.all([feedbackPromise, openaiFeedbackPromise])
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        })
    }
}