/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { ChatRoute } from 'models/ChatRoute';

export function SidebarLinks(props: { routes: ChatRoute[], onThreadClicked: React.MouseEventHandler<HTMLAnchorElement>, onDeleteThread : (threadId : string) => void  }) {
	let location = useLocation();
	let activeColor = useColorModeValue('brand.400', '#000');
	let inactiveColor = useColorModeValue('secondaryGray.900', 'secondaryGray.600');

	const { routes, onDeleteThread } = props;

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string) => {
		return location.pathname.toLowerCase().includes(routeName);
	}

	const hoverState = (routeName: string) => {
		if (!activeRoute(routeName)) {
			return {
				bgColor: useColorModeValue('#f7f7f7', '#f7f7f7'), 
				borderRadius: 5
			}
		}
	}

	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createLinks = (routes: ChatRoute[], onThreadClicked: React.MouseEventHandler<HTMLAnchorElement>) => {
		return routes.map((route: ChatRoute, index: number) => {
			if (route.layout === '/chat') {
				return (
					<NavLink key={index} to={route.layout + route.path} onClick={onThreadClicked}>
						<Box position = "relative" _hover={{ '.trash-icon': { display: 'block' } }}>
							<HStack
								spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
								_hover={hoverState(route.path.toLowerCase())}
								py='5px'
								ps='10px'
								mr='2'>
								<Text
									me='auto'
									// color={activeRoute(route.path.toLowerCase()) ? '#000' : '#eee'}
									color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
									fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}>
									{route.name}
								</Text>
								{ activeRoute(route.path.toLowerCase()) &&
									<Box 
										h='36px' 
										w='4px' 
										bg={activeRoute(route.path.toLowerCase()) ? activeColor : '#fff'}  
									/>
								}
								{ !activeRoute(route.path.toLowerCase()) &&
									<Box 
										h='36px' 
										w='0px' 
										bg={'#fff'}  
									/>
								}
								
							</HStack>
							<Icon
									as={FaTrash}
									boxSize="3"
									cursor="pointer"
									color="red.500"
									ml="2"
									position="absolute"
                                	right="20px"
                                	top="50%"
                                	transform="translateY(-50%)"
									display="none"
                                    className="trash-icon"
									onClick={(e) => {
                                        e.preventDefault();
                                        onDeleteThread(route.threadId); 
                                    }}
								/>
						</Box>
					</NavLink>
				);
			}
		}
	)}
	
	return <>{createLinks(routes, props.onThreadClicked)}</>
}

export default SidebarLinks;
