// Chakra imports
import { LockIcon } from '@chakra-ui/icons';
import { Box, useDisclosure, Alert, AlertIcon, Button, HStack, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Portal } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { ChatRoute } from 'models/ChatRoute';
import { Thread } from 'models/Thread';
import { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { CompositionService } from 'services/CompositionService';
import { OpenAIService } from 'services/OpenAIService';
import { UserService } from 'services/UserService';
import { Constants } from 'utils/constants';
import Chat from 'views/chat';
import { FirebaseService } from 'views/chat/firebase.services';
import Navbar from 'components/navbar/NavbarAdmin';
import HomeEmptyView from 'views/HomeEmptyView';

// Custom Chakra theme
export default function Home(props: { [x: string]: any }) {
	const { ...rest } = props;

	const userService = new UserService();
	const firebaseService = new FirebaseService();
	const openAiService = new OpenAIService();
	const compositionService = new CompositionService();

	const history = useHistory()
	const [toggleSidebar, setToggleSidebar] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isValidatingUserId, setIsValidatingUserId] = useState<boolean>(false);
	const [userDocumentId, setUserDocumentId] = useState<string>(localStorage.getItem(Constants.UserDocumentId) || '');
	const [showUserIdError, setShowUserIdError] = useState<boolean>(false);
	const [showUserResetError, setShowUserResetError] = useState<boolean>(false);
	const [threads, setThreads] = useState<Thread[] | []>([]);
	const [routes, setRoutes] = useState<ChatRoute[]>([]);

	document.documentElement.dir = 'ltr';

	//#region useEffect

	useEffect(() => {
		showUserIdPromptIfNeeded();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!userDocumentId) {
			return
		}

		userService
			.getUser(userDocumentId)
			.then(threads => {
				setThreads(threads)
				createRoutesFromThreads(threads)
			}).catch(_ => {
				/**
				 * Reset user related variables
				 */
				setUserDocumentId('')
				localStorage.removeItem(Constants.UserDocumentId)
				localStorage.removeItem(Constants.ThreadId)

				/**
				 * Display user prompt again
				 */
				setShowUserResetError(true)
				onOpen()
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	//#endregion

	//#region SideBar Routing

	const getRoutes = (routes: ChatRoute[]): any => {
		return routes.map((route: ChatRoute, key: any) => {
			if (route.layout === '/chat') {
				return <Route path="/chat/:threadId" component={route.component} key={key} />;
			} else {
				return null;
			}
		});
	};

	//#endregion Navigation Bar

	//#region User ID Prompt

	const showUserIdPromptIfNeeded = () => {
		const localUserDocumentId = localStorage.getItem(Constants.UserDocumentId)
		if (!localUserDocumentId) {
			/**
			 * Display modal prompt asking for their user id
			 */
			onOpen()
		} else {
			setUserDocumentId(localUserDocumentId);
		}
	}

	/**
	 * When the "Lets go" button is clicked in the user prompt
	 */
	const onClickEnter = () => {
		setIsValidatingUserId(true)
		setShowUserResetError(false)

		userService
			.getUser(userDocumentId)
			.then(threads => {
				setShowUserIdError(false)
				setShowUserResetError(false)
				setThreads(threads)
				createRoutesFromThreads(threads)
				localStorage.setItem(Constants.UserDocumentId, userDocumentId)
				onClose()

				return firebaseService.updateUserActive(userDocumentId)
			}).then(() => {
				/**
				 * Do nothing
				 */
			}).catch(_ => {
				setShowUserIdError(true)
			})
			.finally(() => {
				setIsValidatingUserId(false)
			})
	}

	/**
	 * Fires when the user types in the user id textfield
	 * @param e The textfield for the user prompt
	 */
	const onUserIdChange = (e: any) => {
		setUserDocumentId(e.target.value)
	}

	/**
	 * When the user clicks "Enter" in the user prompt
	 * @param Event The keyboard event
	 */
	const onUserIdKeyDown = (Event: any) => {
		setShowUserIdError(false)

		if (Event.key !== 'Enter' || userDocumentId.length === 0) {
			return
		}

		onClickEnter()
	}

	//#endregion User ID Prompt

	//#region New chat thread, route creations, etc

	const createNewChatThread = async () => {
		try {
			const newThread = await openAiService.createThread()
			await firebaseService.addThreadIDToUser(userDocumentId, newThread)
			addNewThread(newThread)
			localStorage.setItem(Constants.ThreadId, newThread.id)
			history.push(`/chat/${newThread.id}`)
		} catch (error) {
			console.error(error)
		}
	}

	const addNewThread = (thread: Thread) => {
		const updatedThreads = [thread].concat(threads)
		setThreads(updatedThreads)
		createRoutesFromThreads(updatedThreads)
	}

	const deleteThread = (threadId: string) => {
		compositionService.deleteThread(userDocumentId, threadId)
			.then(() => {
				const updatedThreads = threads.filter(thread => thread.id !== threadId)
				setThreads(updatedThreads)
				createRoutesFromThreads(updatedThreads)
				goHome()
			}).catch(error => {
				console.error(error)
			})
	}

	const createRoutesFromThreads = (threads: Thread[] | []) => {
		if (threads.length === 0) {
			createNewChatThread()
			return
		}

		const chatRoutes: ChatRoute[] = threads.map(thread => {
			return {
				name: thread.name,
				layout: '/chat',
				component: Chat,
				path: `/${thread.id}`,
				secondary: false,
				threadId: thread.id
			}
		})

		setRoutes(chatRoutes)
	}

	const goHome = () => {
		history.replace('/')
	} 

	//#endregion New chat thread, route creations, etc

	const getActiveNavbar = (routes: ChatRoute[]): boolean => {
		let activeNavbar = false;

		routes.forEach(route => {
			if (window.location.href.indexOf(route.layout + route.path) !== -1) {
				return route.secondary;
			}
		})

		return activeNavbar;
	};

	return (
		<Box>
			{ routes.length > 0 &&
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar
					}}>
					<Sidebar routes={routes} display='none' {...rest} onClick={createNewChatThread} onDeleteThread = {deleteThread} />
					<Box
						float='right'
						minHeight='100vh'
						height='100%'
						overflow='auto'
						position='relative'
						maxHeight='100%'
						w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
						transitionDuration='.2s, .2s, .35s'
						transitionProperty='top, bottom, width'
						transitionTimingFunction='linear, linear, ease'>
						<Portal>
							<Box>
								<Navbar
									onOpen={onOpen}
									secondary={getActiveNavbar(routes)}
									routes={routes}
									onClick={createNewChatThread}
									onDeleteThread={deleteThread}
									{...rest}
								/>
							</Box>
						</Portal>
						
						<Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='95vh' pt='50px'>
							{ history.location.pathname === '/' &&
								<HomeEmptyView />
							}
							<Switch>
								{getRoutes(routes)}
							</Switch>
						</Box>
						<Box>
							<Footer />
						</Box>
					</Box>
				</SidebarContext.Provider>
			}

			{/**
			 * Modal prompting for the User Id
			 */}
			<Modal isCentered isOpen={isOpen} onClose={onClose}>
				<ModalOverlay
					bg='whiteAlpha.700'
					backdropFilter='blur(10px) hue-rotate(90deg)'
				/>
				<ModalContent>
					<ModalHeader></ModalHeader>
					<ModalBody>
						<VStack spacing={5}>
							<HStack spacing={5}>
								<InputGroup>
									<InputLeftElement pointerEvents='none'>
										<LockIcon color='gray.300' />
									</InputLeftElement>
									<Input
										type='text'
										placeholder='Enter User Id'
										onChange={onUserIdChange}
										onKeyDown={onUserIdKeyDown}
									/>
								</InputGroup>
								<Button
									backgroundColor={'brand.700'}
									color={'white'}
									_hover={{ bg: 'brand.900' }}
									width={150}
									borderRadius='10px'
									disabled={userDocumentId.length === 0}
									isLoading={isValidatingUserId}
									onClick={() => onClickEnter()}>
									Let's go
								</Button>
							</HStack>
							<Alert status='error' hidden={!showUserIdError} borderRadius={10}>
								<AlertIcon />
								We're unable to validate your user id. Please double check and try again.
							</Alert>
							<Alert status='info' hidden={!showUserResetError} borderRadius={10}>
								<AlertIcon />
								We had to reset your user session. Please enter your user id again.
							</Alert>
						</VStack>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}
