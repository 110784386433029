import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, Stack } from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { ChatRoute } from 'models/ChatRoute';
import { MdAdd } from 'react-icons/md';


function SidebarContent(props: { 
		routes: ChatRoute[], 
		onClick: React.MouseEventHandler<HTMLButtonElement>, 
		onThreadClicked?: React.MouseEventHandler<HTMLAnchorElement>; onDeleteThread : (threadId : string) => void }) {
	const { routes, onClick, onDeleteThread } = props;
	const routesLength = routes.length;

	return (
		<Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
			<Brand />
			<Button 
				leftIcon={<MdAdd />} 
				variant='outline' 
				borderRadius={5}
				width={{"xl": "86%"}}
				minHeight='50px'
				ml="5"
				mr="5"
				_hover={{ bgColor: "#eee" }}
				onClick={onClick}
				disabled={routesLength >= 20}
				>
				New chat
			</Button>
			{routesLength >= 20 && (
				<Alert 
				status='error' 
				mt="4" 
				mx="5" 
				borderRadius="md" 
				width={{ base: "auto", xl: "86%" }} 
				flexDirection="row" 
				alignItems="center" 
				padding="4"
			>
					<AlertIcon />
						<AlertDescription>
							Max 20 chats reached.
						</AlertDescription>
			</Alert>
			)}
			<Stack direction='column' mt='8px' mb='auto'>
				<Box ps='8px' pe={{ lg: '16px', '2xl': '16px' }}>
					<Links routes={routes} onThreadClicked={props.onThreadClicked} onDeleteThread={onDeleteThread} />
				</Box>
			</Stack>
		</Flex>
	);
}

export default SidebarContent;
