import axios, { AxiosRequestConfig } from "axios"
import { Constants } from "utils/constants"
import { DocumentId } from "utils/aliases"
import { HTTPMethods } from "./HTTPMethods"

const API_DELETE_THREAD_DB = `${process.env.REACT_APP_ENV_API_ENDPOINT}/admin/delete-thread`
const API_DELETE_THREAD_OPENAI = (threadId: string) => {
    return `${process.env.REACT_APP_ENV_API_ENDPOINT}/openai/delete-thread?threadId=${encodeURIComponent(threadId)}`
}

export class CompositionService {
    public deleteThread(documentId: DocumentId, threadId: string): Promise<void> {
		return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.post,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                },
                data: {
                    [Constants.DocumentId]: documentId,
                    [Constants.ThreadId]: threadId
                }
            }
            
            const dbPromise = axios.post(API_DELETE_THREAD_DB, requestConfig);
            const openAiPromise = axios.post(API_DELETE_THREAD_OPENAI(threadId), {
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                }
            });

            Promise.all([dbPromise, openAiPromise])
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
}