export enum Constants {
    DocumentId = "documentId",
    ContentType = "Content-Type",
    ApplicationJson = "application/json",
    Prompt = "prompt",
    ThreadId = "threadId",
    UserDocumentId = "userDocumentId",
    Name = "name",
    Result = "result",
    MessageId = "messageId"
}