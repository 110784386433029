import axios, { AxiosRequestConfig } from "axios"
import { Thread } from "models/Thread"
import { ThreadParser } from "parsers/ThreadParser"
import { Constants } from "utils/constants"
import { Dictionary, DocumentId } from "utils/aliases"
import { HTTPMethods } from "./HTTPMethods"

const API_GET_DOCUMENTS = `${process.env.REACT_APP_ENV_API_ENDPOINT}/admin/documents`
const API_POST_THREAD_NAME = `${process.env.REACT_APP_ENV_API_ENDPOINT}/admin/update-thread`

export class UserService {
    private threadParser = new ThreadParser()

    public getUser(documentId: string): Promise<[Thread] | []> {
		return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.get,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                },
                params: {
                    [Constants.DocumentId]: documentId
                }
            }
            
            axios.get(API_GET_DOCUMENTS, requestConfig).then(response => {
                const data = response.data.data.threads
                if (!data) {
                    resolve([])
                    return
                }
                const threads = this.threadParser.parse(data as Dictionary)
                resolve(threads)
            }).catch(error => {
                reject(error)
            })
        })
    }
    
    public updateThreadName(documentId: DocumentId, name: string, threadId: string): Promise<void> {
		return new Promise((resolve, reject) => {
            const requestConfig: AxiosRequestConfig = {
                method: HTTPMethods.post,
                headers: {
                    [Constants.ContentType]: [Constants.ApplicationJson]
                },
                data: {
                    [Constants.DocumentId]: documentId,
                    [Constants.Name]: name,
                    [Constants.ThreadId]: threadId
                }
            }
            
            axios.post(API_POST_THREAD_NAME, requestConfig).then(_ => {
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}